@if $password_strengthen {
  .password-field {
    &__info {
      @include breakpoint($bp--medium-up) {
        &-reset {
          bottom: auto;
          top: 41%;
          #{$ldirection}: 28%;
        }
      }
    }
    &__checkout {
      @include breakpoint($bp--medium-up) {
        #{$ldirection}: 44%;
        width: 220px;
      }
    }
  }
  .password-reset-page {
    .password-reset {
      .password-field {
        &__info {
          &-reset {
            top: -20px;
            left: 332px;
          }
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--black;
      }
    }
  }
  .appt-book-section {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          #{$ldirection}: 60%;
          bottom: auto;
          position: absolute;
          transform: translateY(-92%);
          -webkit-transform: translateY(-92%);
          -moz-transform: translateY(-92%);
          -o-transform: translateY(-92%);
        }
      }
    }
    .booking-step {
      .password-field {
        &__info {
          @include breakpoint($bp--medium-up) {
            #{$ldirection}: 55%;
            bottom: auto;
            position: absolute;
            transform: translateY(-92%);
            -webkit-transform: translateY(-92%);
            -moz-transform: translateY(-92%);
            -o-transform: translateY(-92%);
          }
        }
      }
    }
  }
}
