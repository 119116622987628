.custom-kit-formatter {
  &__landing-content & {
    &__content-wrapper {
      top: 77%;
      #{$ldirection}: 63%;
    }
    &__start-cta {
      background: $color--white;
      color: $color--black;
      font-size: 15px;
    }
  }
}

[dir='rtl'] {
  .site-navigation {
    &__carousel {
      .block-image-hover {
        &__title-inner {
          position: relative;
        }
        &__title {
          line-height: 0;
          padding: 20px;
        }
      }
    }
  }
}
