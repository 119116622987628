.profile-info__fieldset {
  .form_title {
    width: 60% !important;
    label {
      display: block;
    }
  }
  .form_birthday {
    label {
      display: block;
    }
  }
}

#address_form_container {
  .country_container {
    label {
      display: inline-block;
    }
  }
}

.cart-item {
  .product-info__spp-status {
    position: absolute;
    bottom: 0;
  }
}

.favorites-page {
  // Adjust the favorites page "share" buttons to cover the text
  .favorites__share {
    .favorites__share--text,
    .favorites__social-links {
      width: 10em; // Both parts to be the same width to cover any locale's text
      text-align: $rdirection;
    }
    .favorites__social-links {
      // Use a monospace font to standardise the space between <li>s
      font-family: 'tstar_mono_roundregular', monospace;
      li {
        margin: 0px; // Leave 1 monospaced space gap between them
        // Allow the <li>s to be right aligned
        display: inline;
        float: none;
      }
    }
  }
}

#photo-upload-query #choose-file-btn {
  text-transform: uppercase;
}

.favorites-none__link.btn {
  width: 228px;
}

.favorites-page__content .favorites-mylists__create-list .icon-overlay {
  padding-top: 0;
}

.account-order-history {
  .past-purchases {
    .past-purchases__item {
      .order-status {
        .tracking-link-list {
          .order-status,
          .tracking-link {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
}
