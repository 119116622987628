$border_color: #dbdbdb;
$background_color: #d4d4d4;

.checkout-header #promo-message {
  text-transform: uppercase;
  border-bottom: none;
  padding-bottom: 0;
  width: 95%;
  text-align: center;
}

#recommended-products-panel {
  .checkout__panel-content {
    margin-top: 20px;
  }
  .recommended-product-items {
    .recommended-item {
      position: relative;
      min-height: 315px;
      padding-bottom: 85px;
      .column.item_info {
        position: relative;
        .product_name {
          height: 15px;
        }
        .product_subname {
          height: 20px;
        }
      }
      .add_button {
        padding: 10px 0;
      }
      .formatted_price {
        position: relative;
        bottom: 0;
      }
      .addtobag .btn {
        position: absolute;
        bottom: 0;
      }
    }
    .info {
      height: auto;
      max-width: 228px;
    }
    .product_name a {
      border: none;
    }
  }
}

.viewcart {
  #order-summary-panel {
    border-top: none;
  }
}

#checkout-sidebar {
  .additional_info {
    margin-top: 1em;
  }
}

.shipping .checkout-progress__shipping,
.review .checkout-progress__review,
.billing .checkout-progress__billing,
.confirm .checkout-progress__confirm {
  font-weight: bold;
}

.checkout {
  #shipmethod-panel {
    #checkout_shipmethod {
      .ship-method-group {
        // On small screens, allow a bigger margin to stop the text overlapping the button
        // On desktops this is not visible as the dropdown is replaced, but leave this here for debugging
        // Duplicated in \sections\mobile\checkout\_checkout.scss for mobile
        select {
          padding-right: 10%;
          -webkit-padding-end: 10%;
        }
      }
    }
  }
  .viewcart-paypal-button {
    float: left;
    margin-top: 7px;
    width: 33%;
    .terms-conditions {
      margin-top: 10px;
    }
    input {
      opacity: inherit;
      margin-top: 4px;
      float: left;
    }
    .terms-conditions-txt {
      margin-left: 16px;
    }
  }
  ol.checkout-progress {
    li {
      float: left;
      margin-left: 1.5em;
      width: 12.5em;
      list-style-type: decimal;
      margin-top: 1.5em;
    }
  }
  .address-container {
    width: 40%;
    float: left;
  }
  .address_controls {
    #choose-address {
      float: left;
      margin-top: 15px;
    }
  }
  address {
    font-style: normal;
  }
  #review-instructions p {
    margin-top: 15px;
  }
  .cart-item__qty {
    width: 50%;
  }
  .panel.links-panel {
    margin-top: 1em;
  }
  .registration-panel {
    div.password {
      max-width: 400px;
      position: relative;
    }
  }
}

.panel {
  @include breakpoint($bp--medium-down) {
    select {
      // Reinstate the border hidden in mac_base/scss/40-components/forms_generic.scss:111
      height: 57px;
      border-width: 1px;
      margin-bottom: 0;
      option {
        text-decoration: none;
      }
    }
  }
}

.transaction-panel-details {
  margin-top: 2em;
  .trans_detail_item {
    float: left;
    margin-bottom: 2em;
    margin-right: 2em;
    position: relative;
    width: 23.4em;
    word-wrap: break-word;
    .change_link {
      position: absolute;
      top: 0.6em;
      right: 0;
    }
    dd {
      margin: 5px 0 0 10px;
    }
  }
}

.shipping {
  #address_form_container,
  #billing-address-info-panel {
    .form_element {
      margin: 12px 0;
    }
  }
}

.billing {
  .direct-debit label {
    display: block;
  }
  .payment-type {
    float: left;
    margin-right: 0.8em;
  }
  #payment-type-cc {
    margin-top: 15px;
  }
}

.confirm {
  #print-order {
    margin-top: 10px;
  }
}

section.confirmation-panel__container .print-buttons .btn {
  background: none;
}

#payment-panel {
  border-bottom: none;
}

.checkout__content {
  .cart-item__desc-info.loading {
    text-transform: none;
    margin: 1em 0;
    background: url(/media/images/common/ajax-loading.gif) no-repeat center center;
  }
  .order-summary__content {
    .order-summary__shipping-content {
      width: 35%;
      #shipmethod-panel {
        header {
          display: none;
        }
        .content {
          width: 100%;
          @include swap_direction(margin, 0);
          #checkout_shipmethod {
            @include swap_direction(padding, 0);
          }
        }
      }
    }
  }
}

.form-item {
  .password {
    .invalid_marker {
      left: 375px;
      top: 10px;
    }
  }
}

.offer-code__input.form-item {
  padding-top: 7px;
}

.site-container {
  overflow-y: hidden;
  .checkout__sidebar {
    .viewcart-header {
      h2 {
        width: auto;
        padding: 0 18px 0 0;
        margin: 0;
        line-height: 20px;
      }
    }
  }
}

.checkout,
.viewcart {
  .checkout-header {
    &.hasPaypal {
      padding-bottom: 92px;
    }
    #top-viewcart-buttons {
      .continue-buttons {
        padding-right: 13% !important;
        .btn {
          width: 335px !important;
        }
      }
    }
  }
  #bottom-viewcart-buttons {
    .continue-buttons {
      padding-right: 21% !important;
    }
  }
  .checkout__content {
    &.hasPaypal {
      margin: 0;
    }
  }
}

.enhanced_cart_page {
  .viewcart {
    .checkout {
      &__content {
        #viewcart-panel {
          .cart-items {
            .cart-item {
              &__qty-kit-item {
                margin-top: 0;
                padding-top: 10px;
                border: 1px solid $black;
                width: 37px;
                height: 37px;
                padding-#{$ldirection}: 10px;
              }
            }
            .kit-container {
              .cart-item {
                padding: 12px 0;
                border-bottom: none;
                margin-bottom: 10px;
                .color {
                  width: auto;
                  .shade {
                    margin-top: 6px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
