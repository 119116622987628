#google_map_locate_address {
  border: 2px solid;
  height: 300px;
  width: 500px;
  #floating-panel {
    width: 500px;
    height: 20px;
    input#address-latlng {
      width: 33%;
    }
  }
  #google_pick_map {
    width: 496px;
    height: 276px;
  }
}

.site-header__wrapper {
  .cr21-refresh {
    .picker-radio {
      .picker-handle {
        display: none;
      }
    }
  }
}

.welcome15-overlay {
  .site-email-signup__success-offer {
    line-height: 1.5em;
  }
  .welcome-email {
    .site-email-signup__success--welcome-email {
      form {
        .email_input {
          input[type='submit'] {
            padding: 0 7px;
            &.form-submit {
              color: $white;
              background-color: $black;
            }
          }
        }
      }
    }
  }
}

.email-customer-service-form {
  #js-online-order-tip {
    display: none;
  }
  #questions {
    .question-details {
      display: none;
    }
  }
}

.contact-us-thanks-page {
  padding: 6px;
}

#onetrust-button-group-parent {
  position: static !important;
}

.site-footer {
  &--top {
    .grid--item {
      padding: 15px;
    }
  }
}

.homepage-formatter__row {
  .colored-text-block-formatter {
    max-width: none;
  }
}

#power_review_container {
  .full_sign_in {
    h2 {
      letter-spacing: 0;
    }
  }
}
