@mixin opc_update_emea {
  .checkout__content {
    .order-summary__content {
      #shipmethod-panel {
        border: none;
        header {
          display: none;
        }
        .select2-container {
          border: 1px solid #ccc;
          @include swap_direction(padding, 0 0% 0 8px);
          height: 40px;
          .select2-choice {
            height: 45px;
            line-height: 40px;
          }
        }
        .ship-method-home-group {
          margin-bottom: 10px;
          label {
            &.no-hide {
              display: none;
            }
          }
        }
        .ship-method-group-label {
          label {
            width: 80%;
          }
          .picker-radio {
            margin-top: 0;
          }
        }
      }
    }
    #new-account,
    #return-user {
      input[type='submit'] {
        width: 100%;
      }
    }
  }
  .checkout {
    &.active-panel-shipping,
    &.active-panel-review,
    &.payment {
      .checkout__sidebar {
        #need-help-panel {
          display: none;
        }
      }
    }
  }
}
