.store-locator {
  .store-locator-landing__form {
    .form--search--advanced {
      .geo_search.btn,
      .store-locator__submit {
        margin-top: 0;
      }
    }
  }
}
