.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: #{$rdirection};
}

.cleft {
  clear: #{$ldirection};
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        .waitlist-iframe-wrapper {
          width: 95%;
          height: 85%;
          margin: 17px 7px 5px 7px;
        }
        #address_form_container {
          input[type='checkbox'] {
            -webkit-appearance: checkbox;
          }
        }
      }
    }
  }
}

.site-container {
  min-height: 350px;
  .page--spp__product {
    .product__footer {
      a.notify_me {
        padding: 0px 20px;
        width: 100%;
        position: static;
        font-size: 1.5em;
        height: 60px;
        line-height: 64px;
      }
      .product__add-to-bag.coming-soon {
        display: none;
      }
    }
  }
  .grid--mpp {
    &__item {
      .product--not-orderable,
      .prod_inv_status-2 {
        .product__footer {
          li {
            &.temp-out-of-stock {
              padding-top: 0px;
              line-height: 18px;
              .temp-outof-stock_text {
                margin-top: 10px;
              }
            }
            &.coming-soon {
              .coming-soon_text {
                margin-top: 20px;
              }
            }
          }
        }
      }
      .product__header {
        .product__top-info-wrapper {
          height: 90px;
        }
        .product__name {
          padding-#{$rdirection}: 89px;
        }
        .product__description-short {
          @include breakpoint($bp--medium-down) {
            margin-#{$rdirection}: 10px;
          }
        }
        .product__rating {
          @include breakpoint($bp--medium-down) {
            position: absolute;
            #{$rdirection}: 0;
            text-transform: uppercase;
            top: 0;
          }
        }
      }
    }
  }
  .layout--artistry-artist {
    .artist--faves {
      .artist--products__carousel-slide {
        .product--teaser {
          .product__header {
            .product__description-short {
              @include breakpoint($bp--medium-down) {
                margin-#{$rdirection}: 10px;
              }
            }
            .product__rating {
              @include breakpoint($bp--xlarge-up) {
                position: absolute;
                #{$rdirection}: 0;
                text-transform: uppercase;
                top: 0;
              }
            }
            @include breakpoint($bp--large-down) {
              .product_header_details {
                position: relative;
                height: 135px;
                .product__price {
                  float: #{$ldirection};
                  margin-bottom: 0px;
                  position: absolute;
                  bottom: 0;
                  width: 50%;
                }
                .product__faves--teaser.product__faves--teaser_mobile {
                  position: absolute;
                  bottom: 0;
                  text-align: #{$rdirection};
                  float: #{$ldirection};
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  #product-page-livechat-hitarea {
    .lpStaticButton {
      width: 350px;
    }
  }
  .prod_inv_status-3 {
    .product__footer {
      .product__inv-status-item.coming-soon {
        display: block;
      }
    }
  }
}

.site-footer {
  .default_store_data {
    .find_store_link,
    .makeup_services_link {
      margin-bottom: 5px;
      a {
        border-bottom: none;
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (orientation: portrait) {
  .store-locator {
    .form--search--advanced {
      .form--search {
        @include swap_direction(padding, 0 0 74px);
      }
    }
  }
}

.lpPoweredBy {
  display: none;
}

.site-header {
  .site-navigation {
    .checkout-navigation {
      &__menu {
        display: none;
      }
    }
  }
  .site-header__prefix {
    @include breakpoint($width-xxlarge) {
      width: 121px;
      margin-#{$ldirection}: 12px;
    }
    .site-logo {
      margin-#{$ldirection}: 5px;
      .site-logo__image {
        @include breakpoint($width-xxlarge) {
          margin-#{$ldirection}: 0px !important;
        }
      }
    }
  }
  .site-header__menu {
    @include breakpoint($width-xxlarge) {
      width: 864px;
      margin-#{$ldirection}: 172px;
    }
    .site-header__extra {
      @include breakpoint($width-xxlarge) {
        float: right;
      }
    }
  }
  .site-header__suffix {
    @include breakpoint($width-xxlarge) {
      width: 265px;
    }
    .site-header__tools {
      .site-email-signup {
        @include breakpoint($width-xxlarge) {
          padding: 0 14px;
          margin: 0;
          min-width: 0;
        }
      }
      .block-template-site-my-shades-v1 {
        @include breakpoint($width-xxlarge) {
          width: 38px;
        }
      }
    }
  }
}

//for rtl margin-left for the header menu needs to be -100%
[dir='rtl'] .site-header .site-header__menu {
  @include breakpoint($width-xxlarge) {
    margin-left: -100%;
  }
}

// Country picker (splash screen for ME sites)
.country-picker-v1 {
  padding: 18px;
  .welcome-header {
    span {
      vertical-align: middle;
    }
  }
  .language-select__link {
    &.en {
      font-size: 12px;
    }
    &.ar {
      font-size: 20px;
    }
    font-family: $tstar-font;
    display: inline-block;
    vertical-align: middle;
    html[lang='en-e-AE'] &.en,
    html[lang='en-e-SA'] &.en,
    html[lang='ar-e-AE'] &.ar,
    html[lang='ar-e-SA'] &.ar {
      display: none;
    }
  }
  .js-dropdown-target {
    clear: both;
    .form-item-wrapper {
      float: #{$ldirection};
      height: 57px;
      .form-item {
        margin-bottom: 0;
      }
    }
    .form-item-wrapper.country_container,
    .form-item-wrapper.language_container {
      width: 246px;
      @include swap_direction(padding, 0 5px 0 0);
    }
    .submit_container {
      .btn--no-border.submit {
        height: 58px;
        width: 228px;
      }
    }
  }
}

.culture-detail__article-content__meta {
  .culture__social-links {
    min-width: 70px;
  }
}

.product-brief-v1 {
  .product-brief__add-to-bag,
  .product-brief__link-to-spp {
    padding: 0 15px;
  }
}
